<mat-card *ngIf='assignment$ | async' appearance='outlined' class='assignment'>
    <mat-card-content *ngIf='!(isLoading$ | async)'>
        <div *ngIf='selectedUsers?.length > 0' authz='Teacher#R, Admin#R' class='student-name-header'>
            <span>Editing Assignment For:</span>
            <span *ngFor='let user of selectedUsers' class='student-name'>
                {{ user.firstName + ' ' + user.lastName }}
            </span>
        </div>

        <div authz='User#R'>
            <div [hidden]='isAdmin'>
                <div style='text-align: center;margin-bottom: 15px;'>
                    <button (click)='toggleExpand(false)' *ngIf='expanded' mat-raised-button type='button'>Collapse
                        Instructions
                    </button>
                    <button (click)='toggleExpand(true)' *ngIf='!expanded' mat-raised-button type='button'>Expand
                        Instructions
                    </button>
                </div>
                <div *ngIf='expanded' class='userSection' innerHTML='{{ instructionsBody }}'></div>
                <div>
                    <iframe *ngIf='assignment?.googleDocId' [src]='googleDocSrc | safe'
                        [style]='iframeStyle'>
                    </iframe>
                </div>
                <div style='margin: 20px 0px;'>
                    <div *ngFor='let at of fileAttachements'>
                        <a href='{{at.path}}' target='_blank'>{{ at.originalname }}</a>
                    </div>
                </div>
            </div>
        </div>

        <mat-tab-group authz='Admin#R'>
            <mat-tab label='Summary'>
                <div *ngIf='allStudents$ | async' class='summary'>
                    <form [formGroup]='homeWorkForm'>
                        <div>
                            <mat-form-field [hideRequiredMarker]='hideRequiredControl.value'
                                floatLabel='auto'>
                                <input formControlName='name' matInput placeholder=''>
                                <mat-label>Assignment Name</mat-label>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field [hideRequiredMarker]='hideRequiredControl.value'
                                floatLabel='auto'>
                                <mat-label>Due Date</mat-label>
                                <input [matDatepicker]='picker' formControlName='dueDate' matInput>
                                <mat-datepicker-toggle [for]='picker' matSuffix></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div authz='Admin#R'>
                            <mat-form-field [hideRequiredMarker]='hideRequiredControl.value'
                                floatLabel='auto'>
                                <mat-label>Student</mat-label>
                                <mat-select [(ngModel)]='selectedUsers' [compareWith]='compare'
                                    [ngModelOptions]='{standalone: true}'
                                    multiple required>
                                    <mat-option *ngFor='let user of students' [value]='user'>
                                        {{ user.firstName + ' ' + user.lastName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf='teachers$ | async' authz='Admin#R'>
                            <mat-form-field [hideRequiredMarker]='hideRequiredControl.value' appearance='fill'
                                class='teachers-chip-list'>
                                <mat-label>Teachers</mat-label>
                                <mat-chip-grid #chipList aria-label='Teachers'>
                                    <mat-chip-row (removed)='removeTeacher(teacher)' *ngFor='let teacher of teachers'>
                                        {{ teacher.firstName }} {{ teacher.lastName }}
                                        <mat-icon *ngIf='removable' matChipRemove>cancel</mat-icon>
                                    </mat-chip-row>
                                    <input #teacherInput
                                        (matChipInputTokenEnd)='addTeacher($event)'
                                        [matAutocomplete]='teacher'
                                        [matChipInputAddOnBlur]='addOnBlur'
                                        [matChipInputFor]='chipList'
                                        [matChipInputSeparatorKeyCodes]='separatorKeysCodes'
                                        placeholder='Add teacher..'>
                                </mat-chip-grid>
                                <mat-autocomplete #teacher='matAutocomplete' (optionSelected)='selectedTeacher($event)'>
                                    <mat-option *ngFor='let user of filteredTeachers | async' [value]='user'>
                                        {{ user.firstName }} {{ user.lastName }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field [hideRequiredMarker]='hideRequiredControl.value'
                                floatLabel='auto'>
                                <input formControlName='grade' matInput max='200' min='0' placeholder='' type='number'>
                                <mat-label>Grade</mat-label>
                            </mat-form-field>
                        </div>
                    </form>
                    <div>
                        <mat-checkbox [(ngModel)]='isGroupAssignment'>Group Assignment</mat-checkbox>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label='Instructions'>
                <div class='instructions'>
                    <div authz='Admin#R'>
                        <angular-editor [(ngModel)]='instructionsBody' [config]='config'></angular-editor>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label='Work'>
                <iframe *ngIf='assignment?.googleDocId' [src]='googleDocSrc | safe'
                    [style]='iframeStyle'>
                </iframe>
                <div *ngIf='!assignment?.googleDocId'>
                    <div style='margin: 20px 20px; text-align: center;'>
                        <button
                            (click)='createGoogleDoc()'
                            [disabled]='assignment?.name ? false : true'
                            color='primary'
                            mat-raised-button
                            type='button'>CREATE DOCUMENT
                        </button>
                        <button
                            (click)='createGoogleSheet()'
                            [disabled]='assignment?.name ? false : true'
                            color='primary'
                            mat-raised-button
                            type='button'>CREATE SHEET
                        </button>
                        <div *ngIf='!assignment?.name'>You must have an assignment name to create a work document.</div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label='Attachments'>
                <div style='margin: 20px 20px;'>
                    <div *ngFor='let at of fileAttachements' class='file-attachments'>
                        <a href='{{at.path}}' target='_blank'>{{ at.originalname }}</a>
                        <button (click)='deleteAttachment(at)'>
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>

        <form [formGroup]='homeWorkForm'>
            <div [class]="{'hidden': showAddAttachments == false }">
                <app-upload (attachfiles)='addFilesToAssignment($event)'></app-upload>
            </div>
            <div class='bottom-buttons'>
                <button (click)='addBody()' *ngIf='!assignment?.attachments'
                    authz='Admin#R' color='accent'
                    mat-raised-button>Add Body
                </button>
                <button (click)='showAddAttachments = !showAddAttachments;'
                    color='secondary'
                    mat-raised-button>
                    {{ showAddAttachments === false ? 'Add Attachments' : 'Hide Add Attachments' }}
                </button>
                <!--        <button (click)="addNote()" *ngIf="assignment?._id"-->
                <!--          authz="Admin#R" color="accent"-->
                <!--          mat-raised-button>Add Note-->
                <!--        </button>-->
                <button (click)='copy()' *ngIf='assignment?._id'
                    authz='Admin#R' color='secondary' mat-raised-button>Copy
                </button>
                <button (click)='createAssignments()' *ngIf='!assignment?._id'
                    authz='Admin#R' color='primary' mat-raised-button>Save
                </button>
                <button (click)='update()' *ngIf='assignment?._id'
                    authz='Admin#R' color='primary' mat-raised-button>Update
                </button>
                <button (click)='update(true)' *ngIf='assignment?._id && !assignment.submitted' color='primary'
                    mat-raised-button>Submit
                </button>
                <div *ngIf='!isAdmin && assignment?.submitted' class='submit-message'>
                    You have submitted this assignment but you can still change it as needed.
                </div>
                <button (click)='update(false)' *ngIf='assignment?._id && assignment.submitted' authz='Admin#R'
                    color='primary'
                    mat-raised-button>UnSubmit
                </button>
                <!--        <button mat-raised-button color="primary" (click)="close()">Close</button>-->
                <button (click)='accept(assignment)' *ngIf='assignment?._id && !assignment?.accepted' authz='Admin#U'
                    class='mat-primary'
                    mat-raised-button>Accept
                </button>
                <button (click)='unaccept(assignment)' *ngIf='assignment?._id && assignment?.accepted' authz='Admin#U'
                    class='mat-primary'
                    mat-raised-button>Revert
                </button>
                <button (click)='markBookletComplete(assignment, true)'
                    *ngIf='assignment?._id && !assignment?.bookletComplete'
                    authz='Admin#U'
                    class='mat-primary'
                    mat-raised-button>Mark Booklete Complete
                </button>
                <button (click)='markBookletComplete(assignment, false)'
                    *ngIf='assignment?._id && assignment?.bookletComplete === true'
                    authz='Admin#U'
                    class='mat-primary'
                    mat-raised-button>Mark Booklete NOT Complete
                </button>
            </div>
        </form>
    </mat-card-content>
    <mat-card *ngIf='(isLoading$ | async) || (isSaving$ | async)' appearance='outlined'>
        <mat-card-content>
            <mat-progress-bar mode='indeterminate'></mat-progress-bar>
        </mat-card-content>
    </mat-card>
</mat-card>
