import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BehaviorSubject, forkJoin, merge, Observable, of, switchMap } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { Assignment } from '../../_models/assignment';
import { Attachment } from '../../_models/attachment';
import { Note } from '../../_models/note';
import { User } from '../../_models/user';
import { AuthService } from '../../auth.service';
import { NotifyService } from '../../core/notify.service';
import { StorageService } from '../../core/storage.service';
import { Utils } from '../../core/utils';
import { AssignmentService } from '../../services/assignment.service';
import { AttachmentService } from '../../services/attachment.service';
import { ConfirmDialogService } from '../../services/confirm-dialog.service';
import { FileService } from '../../services/file.service';
import { NoteService } from '../../services/note.service';
import { UserService } from '../../services/user.service';
import { AssignmentUnsubmitNoteComponent } from '../assignment-unsubmit-note/assignment-unsubmit-note.component';

@Component({
    selector: 'app-assignment-edit',
    templateUrl: './assignment-edit.component.html',
    styleUrls: ['./assignment-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignmentEditComponent {
    private redirectToMassEditWhenDone: boolean = false;

    get iframeStyle() {
        if (this.isAdmin) {
            return 'width: 100%; height: 71vh; border: none;';
        } else {
            return 'width: 100%; height: 68vh; border: none;';
        }
    }

    constructor(private fb: FormBuilder,
                private dialog: MatDialog,
                private route: ActivatedRoute,
                private authService: AuthService,
                private dialogService: ConfirmDialogService,
                private userService: UserService,
                private noteService: NoteService,
                private notifyService: NotifyService,
                private attachmentService: AttachmentService,
                private assignmentService: AssignmentService,
                private storage: StorageService,
                private fileService: FileService,
                private router: Router) {

        this.teachers$ = this.userService.getAllTeachers().pipe(
            tap((users: User[]) => {
                this.allTeachers = users.map(it => new User(it));
                if (Utils.isEmpty(this.teachers)) {
                    try {
                        this.teachers = this.storage.get('defaultTeachers');
                        this.homeWorkForm.controls.teachers.setValue(this.teachers);
                        this.storage.save('defaultTeachers', []);
                    } catch (e) {
                        this.teachers = [];
                    }
                }
                this.filteredTeachers = this.teachersCtrl.valueChanges.pipe(
                    startWith(null),
                    map((teachers: string | null) => teachers ? this.filterTeacher(teachers) : this.allTeachers.slice()));
            })
        );

        this.allStudents$ = this.userService.getAllStudents().pipe(
            tap(res => {
                this.students = res.map(i => new User(i));
                if (Utils.isEmpty(this.selectedUsers)) {
                    try {
                        this.selectedUsers = this.storage.get('defaultStudents');
                        this.homeWorkForm.controls.students.setValue(this.selectedUsers);
                        this.storage.save('defaultStudents', []);
                    } catch (e) {
                        this.selectedUsers = [];
                    }
                }
            })
        );

        this.homeWorkForm = this.fb.group({
            name: [null, Validators.required, {disabled: this.readOnly}],
            description: [null, Validators.required, {disabled: this.readOnly}],
            dueDate: [null, Validators.required, {disabled: this.readOnly}],
            attachments: [null],
            notes: [null],
            students: [null, Validators.required, {disabled: this.readOnly}],
            teachers: [null, Validators.required, {disabled: this.readOnly}],
            grade: [null],
            isGroupAssignment: [null]
        });

        this.assignment$ = merge(
            this.route.paramMap.pipe(
                map((paramMap: ParamMap) => paramMap.get('assignmentId')),
                tap(() => {
                    this.isLoading$.next(true);
                }),
                switchMap((assignmentId: string) => {
                    return assignmentId ? this.assignmentService.getAssignment(assignmentId)
                        .pipe(tap(() => this.isLoading$.next(false))) : of(new Assignment());
                }),
                tap(() => {
                    this.isLoading$.next(false);
                }))
        ).pipe(
            tap((assignment: Assignment) => {
                this.assignment = assignment;
                if (this.assignment?.user?.length > 0) {
                    Object.assign(this.selectedUsers, this.assignment.user);
                    console.log(this.selectedUsers);
                    if (this.assignment?.user?.length > 1) {
                        this.isGroupAssignment = true;
                    }
                }
                this.init(true);
            })
        );

        this.isAdmin = this.authService.isAdmin;
    }

    get attachments(): FormArray {
        return this.homeWorkForm.get('attachments') as FormArray;
    }

    get name(): AbstractControl {
        return this.homeWorkForm.get('name');
    }

    get description(): AbstractControl {
        return this.homeWorkForm.get('description');
    }

    get dueDate(): AbstractControl {
        return this.homeWorkForm.get('dueDate');
    }

    get grade(): AbstractControl {
        return this.homeWorkForm.get('grade');
    }

    get notes() {
        return this.homeWorkForm.get('notes') as FormArray;
    }

    allStudents$: Observable<User[]>;
    assignment$: Observable<Assignment>;
    teachers$: Observable<User[]>;

    allTeachers: User[] = [];
    assignmentBody = {};
    googleDocSrc: string = null;
    instructionsBody = '';
    notesBody = '';
    students: User[];
    teachers: User[] = [];
    isGroupAssignment: boolean;

    files: any;
    teacher: any;
    users: User[];
    addOnBlur: any;
    removable = true;
    selectedUser: any;
    selectedUsers = [];
    isLoading$ = new BehaviorSubject<boolean>(false);
    isReadOnly$ = new BehaviorSubject<boolean>(false);
    isSaving$ = new BehaviorSubject<boolean>(false);
    initialized = false;

    assignment: Assignment;
    homeWorkForm: FormGroup;

    isAdmin = false;
    isAttachmentEmpty = false;
    showAddAttachments = false;
    teachersCtrl = new FormControl();
    filteredTeachers: Observable<User[]>;
    assignments = new Array<Assignment>();
    // allAttachments = new Array<Attachment>();
    fileAttachements = new Array<Attachment>();
    // filteredAttachments = new Array<Attachment>();
    @ViewChild('teacherInput') teacherInput: ElementRef;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    hideRequiredControl = new FormControl(false);
    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '10rem',
        minHeight: '5rem',
        placeholder: 'Enter text in this rich text editor....',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Arial',
        customClasses: [
            {
                name: 'Quote',
                class: 'quoteClass',
            },
            {
                name: 'Title Heading',
                class: 'titleHead',
                tag: 'h1',
            },
        ],
        upload: this.uploadFile.bind(this)
    };

    private readOnly: any;
    private savedNotes: any[];
    private savedAttachments: any[];
    deleting: boolean;

    expanded = true;

    uploadFile(file) {
        return this.fileService.upload(file);
    }

    compare(c1: { _id: string }, c2: { _id: string }) {
        return c1 && c2 && c1._id === c2._id;
    }

    init(force: boolean = false) {
        this.isLoading$.next(true);
        this.assignment = new Assignment(this.assignment);
        if (!this.initialized || force) {
            if (!this.assignment) {
                this.assignment = new Assignment();
            }
            this.teachers = [];
            if (this.assignment?.teachers) {
                this.teachers = this.assignment.teachers.map(p => new User(p));
            }
            this.fileAttachements = [];
            let attachments = this.fb.array([this.fb.control('')]);
            if (this.assignment?.attachments && this.assignment?.attachments?.length > 0) {
                attachments = this.fb.array<string>([]);
                this.assignment.attachments.forEach(at => {
                    if (at?.filename && at.filename !== '') {
                        this.fileAttachements.push(at);
                    } else {
                        if (at?.text) {
                            attachments.push(this.fb.control(at.text));
                            this.assignmentBody[at._id] = at.text;
                        } else {
                            attachments.push(this.fb.control(''));
                        }
                    }
                });
                if (attachments.length <= 0) {
                    attachments = this.fb.array([this.fb.control('')]);
                    this.assignment.attachments.push(new Attachment());
                }
            } else {
                this.assignment.attachments = [];
                this.assignment.attachments.push(new Attachment());
            }
            if (this.assignment?.notes && this.assignment.notes.length > 0) {
                if (this.assignment.notes[0]?.body) {
                    this.notesBody = this.assignment.notes[0]?.body;
                }
            } else {
                this.assignment.notes = [];
                this.assignment.notes.push(new Note());
            }
            this.isReadOnly$.next(!this.authService.isAdmin);
            this.homeWorkForm = this.fb.group({
                name: new FormControl({value: this.assignment?.name, disabled: this.isReadOnly$.getValue()},
                    { validators: [Validators.required] }),
                description: new FormControl({value: this.assignment?.description, disabled: this.isReadOnly$.getValue()},
                    { validators: [Validators.required] }),
                dueDate: new FormControl({value: this.assignment?.dueDate, disabled: this.isReadOnly$.getValue()},
                    { validators: [Validators.required] }),
                students: new FormControl({ value: this.students, disabled: this.isReadOnly$.getValue()},
                    { validators: [Validators.required] }),
                teachers: new FormControl({ value: this.teachers, disabled: this.isReadOnly$.getValue()},
                    { validators: [Validators.required] }),
                grade: new FormControl({value: this.assignment?.grade, disabled: this.isReadOnly$.getValue()})
            });
            this.instructionsBody = this.assignment.description;
            if (this.assignment?.googleDocId) {
                this.googleDocSrc = 'https://docs.google.com/document/d/' + this.assignment.googleDocId +
                    '/edit?usp=drivesdk&amp;widget=true&amp;headers=false';
            }
            if (this.assignment?.googleDocId && this?.assignment?.isSheet) {
                this.googleDocSrc = 'https://docs.google.com/spreadsheets/d/' + this.assignment.googleDocId +
                    '/edit?usp=drivesdk&amp;widget=true&amp;headers=false';
            }
        }
        this.attachmentUpdates();
        this.isLoading$.next(false);
    }

    forkJoinSaveAttachments(callback: any) {
        this.savedAttachments = [];
        const saves = new Array<Observable<any>>();
        this.assignment?.attachments.forEach(att => {
            if (att?._id && att._id !== '') {
                saves.push(this.attachmentService.update({attachment: new Attachment(att)}));
            } else {
                saves.push(this.attachmentService.save({attachment: new Attachment(att)}));
            }
        });
        const observable = forkJoin(saves);
        observable.subscribe({
            next: value => this.savedAttachments.push(value),
            complete: () => callback(),
        });
    }

    forkJoinSaveNotes(callback: any) {
        this.savedNotes = [];
        const saves = new Array<Observable<any>>();
        this.assignment?.notes?.forEach(note => {
            if (note?._id && note._id !== '') {
                saves.push(this.noteService.update({note: new Note(note)}));
            } else {
                saves.push(this.noteService.save({note: new Note(note)}));
            }
        });
        const observable = forkJoin(saves);
        observable.subscribe({
            next: value => this.savedNotes.push(value),
            complete: () => callback(),
        });
    }

    addFilesToAssignment($event: any) {
        if (this.files) {
            this.files.push(new Attachment($event));
        } else {
            this.files = [];
            this.files.push(new Attachment($event));
        }
        this.update();
        console.log($event);
    }

    addBody() {
        if (this.assignment?.attachments) {
            this.assignment.attachments.push(new Attachment());
        } else {
            this.assignment.attachments = [];
            this.assignment.attachments.push(new Attachment());
        }
        this.homeWorkForm.controls.attachments = this.fb.array(this.assignment.attachments.map(n => this.fb.control(n.text)));
        console.log(this.homeWorkForm);
    }

    copy() {
        this.assignment = new Assignment(this.assignment);
        this.assignment.attachments = this.assignment.attachments.map(a => {
            delete a._id;
            return a;
        });
        this.assignment._id = null;
        this.assignment.user = null;
        this.selectedUser = null;
        this.init(true);
    }

    createAssignments() {
        this.mergeChangesFromForm();
        if (!this.validateForm()) {
            this.notifyService.warning('Assignment', 'Please fill all the required fields');
            return;
        }
        console.log(this.isGroupAssignment);
        if (this.isGroupAssignment) {
            this.selectedUser = null;
            this.create(() => {
                this.notifyService.success('Success', 'Assignment Created.');
            });
        } else {
            if (this.selectedUsers?.length > 0) {
                this.redirectToMassEditWhenDone = true;
                this.selectedUser = this.selectedUsers.pop();
                if (this.selectedUser) {
                    this.create(() => {
                        if (this.selectedUsers.length > 0) {
                            this.createAssignments();
                        }
                        if (this.redirectToMassEditWhenDone) {
                            this.router.navigate(['/assignment/edit/group/', this.assignment.name ]);
                        }
                        // else {
                        //    this.changes.emit();
                        // }
                    });
                }
            }
        }
    }

    create(callback: () => void) {
        this.isLoading$.next(true);
        const attachmentsCopy = this.assignment.attachments.map(a => {
            delete a._id;
            return new Attachment(a);
        });
        this.saveAttachments(attachmentsCopy, (attachments) => {
            const notesCopy = this.assignment.notes.map(n => {
                delete n._id;
                return new Note(n);
            });
            this.saveNotes(notesCopy, (notes) => {
                this.assignment.notes = notes;
                this.assignment.attachments = attachments;
                if (this.selectedUser) {
                    this.assignment.user = this.selectedUser;
                } else if (this.selectedUsers.length > 1 && this.isGroupAssignment) {
                    Object.assign(this.assignment.user, this.selectedUsers);
                } else if (this.selectedUsers.length === 1) {
                    this.assignment.user = this.selectedUsers[0];
                }
                this.assignmentService.save(this.assignment).subscribe(resp => {
                    console.log(resp);
                    this.isLoading$.next(false);
                    this.notifyService.success('Assignment', 'Saved successfully');
                }, error => {
                    this.isLoading$.next(false);
                    this.notifyService.warning('Assignment', error);
                }, () => {
                    this.isLoading$.next(false);
                    callback();
                });
            });
        });
    }

    async update(submit?: boolean) {
        this.mergeChangesFromForm();
        if (submit !== null && submit === true) {
            this.assignment.submitted = true;
            this.actualUpdate();
        } else if (submit !== null && submit === false) {
            if (this.isValid()) {
                this.reasonForUnsubmit(this.actualUpdate.bind(this));
            }
        } else {
            if (this.isValid()) {
                this.actualUpdate();
            }
        }
    }

    isValid() {
        if (!this.validateForm() && this.assignment?.user?.length > 0) {
            this.notifyService.warning('Assignment', 'Please fill all the required fields');
            return false;
        }
        return true;
    }

    actualUpdate() {
        this.forkJoinSaveAttachments(() => {
            console.log(this.savedAttachments);
            this.assignment.attachments = this.savedAttachments[0];
            this.forkJoinSaveNotes(() => {
                this.isLoading$.next(true);
                this.assignment.notes = this.savedNotes[0];
                this.assignmentService.update(this.assignment).subscribe(() => {
                    this.notifyService.success('Assignment', 'Saved successfully');
                    window.location.reload();
                }, error => {
                    this.isLoading$.next(false);
                    this.notifyService.warning('Assignment', error);
                }, () => {
                    this.isLoading$.next(false);
                });
            });
        });
    }

    saveAttachments(attachments: any, callback: (attachments: any) => void) {
        this.saveAttachment(0, attachments, callback);
    }

    saveAttachment(index: number, attachments: Array<Attachment>, callback) {
        if (index < attachments.length) {
            if (attachments[index]?._id) {
                this.attachmentService.update({attachment: attachments[index]}).subscribe(() => {
                    this.saveAttachment(++index, attachments, callback);
                });
            } else {
                this.attachmentService.save({attachment: attachments[index]}).subscribe(resp => {
                    attachments[index]._id = resp._id;
                    this.saveAttachment(++index, attachments, callback);
                });
            }
        } else {
            callback(attachments);
        }
    }

    saveNotes(notes: any, callback: (attachments: any) => void) {
        this.saveNote(0, notes, callback);
    }

    saveNote(index: number, notes: Array<Note>, callback) {
        if (index < notes.length) {
            if (notes[index]?._id) {
                this.noteService.update({note: notes[index]}).subscribe(() => {
                    this.saveNote(++index, notes, callback);
                });
            } else {
                this.noteService.save({note: notes[index]}).subscribe(resp => {
                    notes[index]._id = resp._id;
                    this.saveNote(++index, notes, callback);
                });
            }
        } else {
            callback(notes);
        }
    }

    hasInvalidAssignments(): boolean {
        let valid = false;
        // tslint:disable-next-line:forin
        this.assignment.attachments.forEach((attachment1) => {
            if (!attachment1?.text || attachment1.text.trim() === '') {
                valid = true;
            } else if (attachment1.text) {
                valid = false;
            }
        });
        if (this.files) {
            this.files.forEach(() => {
                valid = true;
            });
        }
        return (valid);
    }

    mergeChangesFromForm(): void {
        if ((this.assignment.notes as Array<Note>)?.length > 0) {
            if (this.assignment.notes[0]?.body) {
                this.assignment.notes[0].body = this.notesBody;
            } else {
                this.assignment.notes[0] = new Note({body: this.notesBody} as Note);
            }
        } else {
            (this.assignment.notes as Array<Note>)?.push(new Note({body: this.notesBody} as Note));
        }

        if (this.files) {
            this.files.forEach((file) => {
                this.assignment.attachments.push(new Attachment(file));
            });
        }

        if (this.selectedUser) {
            this.assignment.user = this.selectedUser;
        } else if (this.selectedUsers.length > 1 && this.isGroupAssignment) {
            Object.assign(this.assignment.user, this.selectedUsers);
        } else if (this.selectedUsers.length === 1) {
            this.assignment.user = this.selectedUsers[0];
        }

        try {
            for (const key in this.homeWorkForm.controls) {
                // tslint:disable-next-line:forin
                for (const assignKey in this.assignmentBody) {
                    this.assignment.attachments.forEach((at) => {
                        if (assignKey === at._id) {
                            at.text = this.assignmentBody[assignKey];
                        }
                    });
                }
                if (key === 'description') {
                    if (this.instructionsBody?.trim() !== '') {
                        this.assignment.description = this.instructionsBody;
                        this.homeWorkForm.controls[key].setValue(this.assignment.description);
                    }
                } else if (key === 'teachers') {
                    this.assignment.teachers = [];
                    this.teachers.forEach(teacher => {
                        this.assignment.teachers.push(teacher);
                    });
                } else if (key === 'students') {
                    if (this.selectedUsers?.length > 0) {
                        this.homeWorkForm.controls[key].setValue(this.selectedUsers);
                    }
                } else {
                    if (key !== 'attachments') {
                        this.assignment[key] = this.homeWorkForm.controls[key].value;
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    accept(assignment: Assignment) {
        assignment.accepted = true;
        this.assignmentService.update(assignment).subscribe(() => {
            // this.close();
        });
    }

    unaccept(assignment: Assignment) {
        assignment.accepted = false;
        this.assignmentService.update(assignment).subscribe(() => {
            // this.close();
        });
    }

    addTeacher(event: MatChipInputEvent): void {
        console.log(event);
    }

    removeTeacher(teacher: any): void {
        const index = this.teachers.indexOf(teacher);

        if (index >= 0) {
            this.teachers.splice(index, 1);
        }
    }

    filterTeacher(name: string) {
        return this.allTeachers.filter(teacher =>
            teacher.firstName.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

    selectedTeacher(event: MatAutocompleteSelectedEvent): void {
        this.teachers.push(event.option.value);
        this.teacherInput.nativeElement.value = '';
        this.teachersCtrl.setValue(null);
        this.homeWorkForm.controls.teachers.setValue(this.teachers);
    }

    attachmentUpdates() {
        this.isAttachmentEmpty = this.hasInvalidAssignments();
    }

    private reasonForUnsubmit(callback) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;

        const dialogRef = this.dialog.open(AssignmentUnsubmitNoteComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                this.assignment.submitted = false;
                this.assignment.notes.push(new Note({body: data} as Note));
                if (callback) {
                    callback();
                }
            } else {
                this.notifyService.warning('Un-Submit Canceled', 'No reason given or cancel pressed.');
            }
        });
    }

    createGoogleDoc() {
        this.isSaving$.next(true);
        this.assignmentService.createGoogleDoc(this.assignment.name, this.assignment.user[0].email).subscribe(res => {
            this.assignment.googleDocId = res;
            if (this.assignment?.googleDocId) {
                this.googleDocSrc = 'https://docs.google.com/document/d/' +
                    this.assignment.googleDocId +
                    '/edit?usp=drivesdk&amp;widget=true&amp;headers=false';
            }
            if (this.assignment?._id) {
                this.update();
            } else {
                this.createAssignments();
            }
            this.isSaving$.next(false);
        });
    }

    createGoogleSheet() {
        this.isSaving$.next(true);
        this.assignmentService.createGoogleSheet(this.assignment.name, this.assignment.user[0].email).subscribe(res => {
            this.assignment.googleDocId = res;
            this.assignment.isSheet = true;
            if (this.assignment?.googleDocId) {
                this.googleDocSrc = 'https://docs.google.com/spreadsheets/d/' +
                    this.assignment.googleDocId +
                    '/edit?usp=drivesdk&amp;widget=true&amp;headers=false';
            }
            if (this.assignment?._id) {
                this.update();
            } else {
                this.createAssignments();
            }
            this.isSaving$.next(false);
        });
    }

    toggleExpand(b: boolean) {
        this.expanded = b;
    }
    deleteAttachment(attachment: Attachment) {
        this.deleting = true;
        const options = {
            title: 'Delete attachment? ',
            message: 'By deleting you will permanently lose this attachment.',
            cancelText: 'CANCEL',
            confirmText: 'YES, DELETE'
        };

        this.dialogService.open(options);

        this.dialogService.confirmed().subscribe(confirmed => {
            if (confirmed) {
                console.log('Delete;', attachment);
                this.attachmentService.delete(attachment).subscribe(resp => {
                    console.log(resp);
                    window.location.reload();
                }, () => {
                }, () => {
                    this.deleting = false;
                });
            } else {
                this.deleting = false;
            }
        });
    }

    markBookletComplete(assignment: Assignment, b: boolean) {
        this.assignmentService.markCompletedBooklet(assignment, b).subscribe(() => {
            window.location.reload();
        });
    }

    validateForm() {
        this.homeWorkForm.markAllAsTouched();
        const keys = Object.keys(this.homeWorkForm.controls);
        keys.forEach((key) => {
            const c = this.homeWorkForm.controls[key];
            if (c.dirty && c.invalid) {
                c.markAsDirty();
                c.updateValueAndValidity();
            }
        });
        return this.homeWorkForm.valid;
    }
}
