<div authz='Admin#R'>
    <mat-card *ngIf='assignments$ | async as assignments' appearance='outlined' class='assignment'>
        <mat-card-content>
            <h2>Mass Assignment Edit</h2>
            <mat-form-field>
                <mat-label>Assignments</mat-label>
                <mat-select [(ngModel)]='selectedAssignments'>
                    <mat-option (onSelectionChange)='assignmentChange($event)'
                        *ngFor='let assignment of assignments'
                        [value]='assignment'>
                        {{ assignment[0].name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card-content>
    </mat-card>
    <mat-card *ngIf='assignment$ | async' appearance='outlined' class='assignment'>
        <mat-card-content *ngIf='!(isLoading$ | async)'>
            <div>
                <span class="student-name-header">Students:</span>&nbsp;
                <span *ngFor='let user of assignmentUsers; let i = index;' class='student-name'>
                    <span class="student-name">{{ user.firstName + ' ' + user.lastName }}</span> <span *ngIf='i < assignmentUsers.length-1'>,&nbsp;</span>
                </span>
                <br><br>
            </div>
            <mat-tab-group>
                <mat-tab label='Summary'>
                    <div *ngIf='allStudents$ | async' class='summary'>
                        <form [formGroup]='groupEditForm'>
                            <div>
                                <mat-form-field floatLabel='auto'
                                    hideRequiredMarker='false'>
                                    <input formControlName='name' matInput placeholder=''>
                                    <mat-label>Assignment Name</mat-label>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field floatLabel='auto'
                                    hideRequiredMarker='false'>
                                    <mat-label>Due Date</mat-label>
                                    <input [matDatepicker]='picker' formControlName='dueDate' matInput>
                                    <mat-datepicker-toggle [for]='picker' matSuffix></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <!--              <div authz='Admin#R'>-->
                            <!--                <mat-form-field hideRequiredMarker='false'-->
                            <!--                  floatLabel='auto'>-->
                            <!--                  <mat-label>Student</mat-label>-->
                            <!--                  <mat-select [(ngModel)]='selectedUsers' [compareWith]='compare' [ngModelOptions]='{standalone: true}'-->
                            <!--                    multiple required>-->
                            <!--                    <mat-option *ngFor='let user of students' [value]='user'>-->
                            <!--                      {{user.firstName + ' ' + user.lastName}}-->
                            <!--                    </mat-option>-->
                            <!--                  </mat-select>-->
                            <!--                </mat-form-field>-->
                            <!--              </div>-->
                            <div *ngIf='teachers$ | async' authz='Admin#R'>
                                <mat-form-field appearance='fill' class='teachers-chip-list'>
                                    <mat-label>Teachers</mat-label>
                                    <mat-chip-grid #chipList aria-label='Teachers'>
                                        <mat-chip-row (removed)='removeTeacher(teacher)'
                                            *ngFor='let teacher of teachers'>
                                            {{ teacher.firstName }} {{ teacher.lastName }}
                                            <mat-icon *ngIf='removable' matChipRemove>cancel</mat-icon>
                                        </mat-chip-row>
                                        <input #teacherInput
                                            (matChipInputTokenEnd)='addTeacher($event)'
                                            [matAutocomplete]='teacher'
                                            [matChipInputAddOnBlur]='addOnBlur'
                                            [matChipInputFor]='chipList'
                                            [matChipInputSeparatorKeyCodes]='separatorKeysCodes'
                                            placeholder='Add teacher..'>
                                    </mat-chip-grid>
                                    <mat-autocomplete #teacher='matAutocomplete'
                                        (optionSelected)='selectedTeacher($event)'>
                                        <mat-option *ngFor='let user of filteredTeachers | async' [value]='user'>
                                            {{ user.firstName }} {{ user.lastName }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </form>
                        <!--            <div>-->
                        <!--              <mat-checkbox [(ngModel)]='isGroupAssignment'>Group Assignment</mat-checkbox>-->
                        <!--            </div>-->
                    </div>
                </mat-tab>
                <mat-tab label='Instructions'>
                    <div class='instructions'>
                        <div authz='Admin#R'>
                            <angular-editor [(ngModel)]='instructionsBody' [config]='config'></angular-editor>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label='Attachments'>
                    <div style='margin: 20px 20px;'>
                        <div *ngFor='let at of fileAttachements'><a href='{{at.path}}'
                            target='_blank'>{{ at.originalname }}</a></div>
                    </div>
                </mat-tab>
            </mat-tab-group>

            <form [formGroup]='groupEditForm'>
                <div [class]="{'hidden': showAddAttachments == false }">
                    <app-upload (attachfiles)='addFilesToAssignment($event)'></app-upload>
                </div>
                <div class='bottom-buttons'>
                    <button (click)='showAddAttachments = !showAddAttachments;'
                        color='secondary'
                        mat-raised-button>
                        {{ showAddAttachments === false ? 'Add Attachments' : 'Hide Add Attachments' }}
                    </button>
                    <button (click)='update()' *ngIf='assignment?._id'
                        authz='Admin#R' color='primary' mat-raised-button>Update
                    </button>
                </div>
            </form>
        </mat-card-content>
        <mat-card *ngIf='(isLoading$ | async) || (isSaving$ | async)' appearance='outlined'>
            <mat-card-content>
                <mat-progress-bar mode='indeterminate'></mat-progress-bar>
            </mat-card-content>
        </mat-card>
    </mat-card>
</div>
