import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { Assignment } from '../../../_models/assignment';
import { AssignmentService } from '../../../services/assignment.service';
import { ClassroomService } from '../../../services/classroom.service';

class AssignmentParams {
    filterBy = 'all';
    selectedUser = '';
    selectedDate: Date = null;

    constructor(filterBy, selectedUser, selectedDate) {
        this.filterBy = filterBy;
        this.selectedUser = selectedUser;
        this.selectedDate = selectedDate;
    }

    toHttpParams() {
        const tmp = new HttpParams();
        if (this.filterBy) {
            tmp.set('filterBy', this.filterBy);
        }
        if (this.selectedUser) {
            tmp.set('selectedUser', this.selectedUser);
        }
        if (this.selectedDate) {
            tmp.set('selectedDate', this.selectedDate.toISOString());
        }
        return tmp;
    }
}

@Component({
    selector: 'app-classroom-assignment',
    templateUrl: './classroom-assignment.component.html',
    styleUrls: ['./classroom-assignment.component.scss']
})
export class ClassroomAssignmentComponent implements OnInit {
    assignmentList$: Observable<Assignment[]>;
    assignmentList = new BehaviorSubject<string>('');
    filterBy: string;
    selectedUser: string;
    selectedDate: Date;
    classrooms: any;

    constructor(private assignmentService: AssignmentService,
                private classroomService: ClassroomService) {
        // this.assignmentList$ = this.assignmentService
        //     .getAssignmentsByQueryString(
        //         this.serialize(
        //             new AssignmentParams(this.filterBy, this.selectedUser, this.selectedDate)
        //         )
        //     );

        this.assignmentList$ = merge(
            this.assignmentList.pipe(() => this.assignmentService
                .getAssignmentsByQueryString(
                    this.serialize(
                        new AssignmentParams(this.filterBy, this.selectedUser, this.selectedDate)
                    )
                )),
        );

        this.classrooms = this.classroomService.getClassrooms().subscribe((cr) => {
            this.classrooms = cr;
        });
    }

    ngOnInit(): void {
    }

    save($event: any) {
        console.log('changing values', $event);
        // this.assignmentService.update(assignment).subscribe((res) => {
        //   this.assignmentList.next(null);
        // });
    }

    private serialize(assignmentParams: AssignmentParams) {
        return assignmentParams.toHttpParams().toString();
    }
}
