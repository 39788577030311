import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AttachmentManagerComponent } from './admin/attachment/attachment-manager/attachment-manager.component';
import { BlogAddComponent } from './admin/blog/blog-add/blog-add.component';
import { BlogGridComponent } from './admin/blog/blog-grid/blog-grid.component';
import { ClassroomAssignmentComponent } from './admin/classroom/classroom-assignment/classroom-assignment.component';
import { ClassroomEditComponent } from './admin/classroom/classroom-edit/classroom-edit.component';
import { ClassroomPageComponent } from './admin/classroom/classroom-page/classroom-page.component';
import { UserAddEditComponent } from './admin/users/user-add-edit/user-add-edit.component';
import { UserPageComponent } from './admin/users/user-page/user-page.component';
import { AssignmentEditComponent } from './assignment/assignment-edit/assignment-edit.component';
import { AssignmentGroupEditComponent } from './assignment/assignment-group-edit/assignment-group-edit.component';
import { AssignmentPageComponent } from './assignment/assignment-page/assignment-page.component';
import { AuthGuardService as AuthGuard } from './auth-guard.service';
import { BooklogComponent } from './booklog/booklog-page/booklog.component';
import { GradesComponent } from './grades/grades.component';
import { HomeComponent } from './home/home.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { MessengerPageComponent } from './messenger-page/messenger-page.component';
import { PhotoUploadComponent } from './photo/photo-upload/photo-upload.component';
import { NewsletterSubmissionComponent } from './public/newsletter-submission/newsletter-submission.component';
import { CalendarPageComponent } from './user/calendar-page/calendar-page.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
    },
    {
        path: 'response-reset-password/:resetToken',
        component: ResetPasswordComponent,
    },
    {
        path: 'photo/upload',
        component: PhotoUploadComponent,
    },
    {
        path: 'RavensReviewSubmit',
        component: NewsletterSubmissionComponent,
    },
    {
        path: 'admin/user',
        component: UserPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'admin/user/edit',
                component: UserAddEditComponent,
            },
            {
                path: 'admin/user/edit/:userId',
                component: UserAddEditComponent,
            },
        ]
    },
    {
        path: 'assignment',
        component: AssignmentPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'assignment/edit',
                component: UserAddEditComponent,
            },
            {
                path: 'assignment/edit/:assignmentId',
                component: UserAddEditComponent,
            },
        ]
    },
    {
        path: 'classroom/assignment',
        component: ClassroomAssignmentComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'calendar',
        component: CalendarPageComponent,
    },
    {
        path: 'attachments',
        component: AttachmentManagerComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'blogs',
        component: BlogGridComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'blogs/edit',
        component: BlogAddComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Edit Blog'}
    },
    {
        path: 'blogs/edit/:blogId',
        component: BlogAddComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Edit Blog'}
    },
    {
        path: 'booklog',
        component: BooklogComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Booklog'}
    },
    {
        path: 'grades',
        component: GradesComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Grades'}
    },
    {
        path: 'admin/classroom',
        component: ClassroomPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'admin/classroom/edit',
                component: ClassroomEditComponent,
            },
            {
                path: 'admin/classroom/edit/:classroomId',
                component: ClassroomEditComponent,
            },
        ]
    },
    {
        path: 'admin/classroom/assignments',
        component: ClassroomAssignmentComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Assignments By Classroom'}
    },
    {
        path: 'assignment/edit',
        component: AssignmentEditComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'New Assignment'}
    },
    {
        path: 'assignment/edit/group',
        component: AssignmentGroupEditComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Group Edit Assignment'}
    },
    {
        path: 'assignment/edit/group/:assignmentGroupSearchTerm',
        component: AssignmentGroupEditComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Group Edit Assignment'}
    },
    {
        path: 'assignment/edit/:assignmentId',
        component: AssignmentEditComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Edit Assignment'}
    },
    {
        path: 'admin/classroom/edit',
        component: ClassroomEditComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'New Classroom'}
    },
    {
        path: 'admin/classroom/edit/:classroomId',
        component: ClassroomEditComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Edit Classroom'}
    },
    {
        path: 'admin/user/edit',
        component: UserAddEditComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Edit User'}
    },
    {
        path: 'admin/user/edit/:userId',
        component: UserAddEditComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Edit User'}
    },
    {
        path: 'messenger',
        component: MessengerPageComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Messenger'}
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
