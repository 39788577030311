import { NavItem } from './nav-item';

export let menu: NavItem[] = [
    {
        authz: 'User#R',
        displayName: 'Dashboard',
        disabled: true,
        iconName: 'dashboard',
        route: '/'
    },
    // {
    //   authz: 'User#R',
    //   displayName: 'Calendar',
    //   iconName: 'calendar_today',
    //   route: '/calendar'
    // },
    {
        authz: 'Admin#R',
        displayName: 'Classroom',
        iconName: 'group',
        route: 'admin/classroom',
        children: [
            {
                authz: 'Admin#R',
                displayName: 'View Classrooms',
                iconName: 'list',
                route: 'admin/classroom'
            },
            {
                authz: 'Admin#R',
                displayName: 'Add Classroom',
                iconName: 'add_box',
                route: 'admin/classroom/edit'
            },
            {
                authz: 'Admin#R',
                displayName: 'Add Assignments',
                iconName: 'add_box',
                route: 'classroom/assignment'
            },
            {
                authz: 'Admin#R',
                displayName: 'Assign Classroom',
                iconName: 'assign',
                route: 'admin/classroom/assignments'
            }
        ]
    },
    {
        authz: 'User#R',
        displayName: 'Booklog',
        iconName: 'book',
        route: 'booklog',
    },
    {
        authz: 'Admin#R',
        displayName: 'Grades',
        iconName: 'grade',
        route: 'grades',
    },
    {
        authz: 'User#R',
        displayName: 'Assignments',
        iconName: 'assignment',
        route: 'assignment',
        children: [
            {
                authz: 'User#R',
                displayName: 'View Assignments',
                iconName: 'list',
                route: 'assignment'
            },
            {
                authz: 'Admin#R',
                displayName: 'Mass Edit Assignments',
                iconName: 'list',
                route: 'assignment/edit/group'
            }
        ]
    },
    {
        authz: 'Admin#R',
        displayName: 'Admin',
        iconName: 'settings',
        route: 'attachments',
        children: [
            {
                authz: 'Admin#R',
                displayName: 'Add Blog Post',
                iconName: 'add',
                route: 'blogs/edit'
            },
            {
                authz: 'Admin#R',
                displayName: 'Blog Post List',
                iconName: 'list',
                route: 'blogs'
            },
            {
                authz: 'Admin#R',
                displayName: 'Attachments List',
                iconName: 'list',
                route: 'attachments'
            },
        ]
    },
    // {
    //     authz: 'User#R',
    //     displayName: 'Messenger',
    //     iconName: 'chat',
    //     route: 'messenger',
    // },
    // {
    //   authz: 'User#R',
    //   displayName: 'Photo Upload',
    //   iconName: 'photo',
    //   route: 'photo/upload',
    // },
    {
        authz: 'User#R',
        displayName: 'Submission Page',
        iconName: 'feed',
        route: 'RavensReviewSubmit',
    },
    {
        authz: 'Admin#R',
        displayName: 'User Management',
        iconName: 'face',
        route: 'admin/user',
        children: [
            {
                authz: 'Admin#R',
                displayName: 'User List',
                iconName: 'list',
                route: 'admin/user'
            },
        ]
    },
    {
        authz: 'User#R',
        displayName: 'Sign Out',
        iconName: 'highlight_off'
    }
];
